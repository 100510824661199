import React from "react"
import styled from "styled-components"
import Layout from "../../../template/Layout"

/**
 * Material
 */
import Head from "../../../head"
import { ContactDescription } from "../common/ContactDescription"
import {
  Props as BreadcrumbsData,
} from "../../common/Breadcrumbs"
import { RESPONSIVE_STYLES, TABLET_STYLES } from './../../../AppConst';
import { ContactContainer } from "../common/ContactContainer"
import { ContactFormToTopButton } from "../common/ContactFormToTopButton"
import { SectionTitle } from "../../common/SectionTitle"

/**
 * Props
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
}

/**
 * Component
 */
export const RecruitAccepted: React.VFC<Props> = (props) => {
  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head />
      <ContactContainer>
        <SectionTitle title='CONTACT' subTitle='お問い合わせ' />
        <ContactDescription title="お問い合わせを受付いたしました" step={3} />
        <ContactFormToTopButton />
      </ContactContainer>
    </Layout>
  )
}
