import React from "react"

/**
 * Material
 */
import { RecruitAccepted } from "../../../components/contact/recruit/accepted"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `CONTACT`, path: `contact` },
  { text: `採用エントリー`, path: `contact/recruit` },
]

/**
 * Component
 */
export default function Home() {
  return (
    <RecruitAccepted breadcrumbsData={breadcrumbsData} />
  )
}